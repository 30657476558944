<template>
  <div :style="backCss" class="excel-view">
    <div class="excel-view-letf">
      <div class="excel-top">
        <!-- <a-input-search placeholder="请输入职位或人员昵称" style="width: 100%" @search="onSearch" /> -->
        <a-select
          showSearch
          allowClear
          :disabled="radioValue == 3"
          showArrow
          placeholder="请人员昵称"
          v-model="selectUserId"
          @change="userSelect"
          option-filter-prop="label"
          style="width: 100%; position: relative"
        >
          <a-select-option
            v-for="user in allUsers.filter(item => !item.enabled)"
            :label="user.userName"
            :key="user.userId"
          >
            <div
              style="width: 100%;
                     line-height: 30px;
                     display: inline-block;
                     color: rgba(0, 0, 0, 0.65);
                     display: flex;
                     justify-content: center;
                     align-items: center;
                    "
              target="_blank"
            >
              <img style="width: 20px; height: 20px; border-radius: 50%" :src="user.avatar" alt />
              <div style="width: 100px;margin-left:10px">{{ user.userName }}</div>
            </div>
          </a-select-option>
        </a-select>
      </div>
      <div class="tree-list">
        <a-spin style="min-height:70px" :spinning="treeLoading">
          <a-tree @expand="expand" :expandedKeys="expandedKeys" :tree-data="treeData" @select="treeSelect">
            <template slot="title" slot-scope="{ title }">
              <span v-if="title.indexOf(searchValue) > -1">
                {{ title.substr(0, title.indexOf(searchValue)) }}
                <span :style="'color:' + color">{{ searchValue }}</span>
                {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
              </span>
              <span v-else>{{ title }}</span>
            </template>
          </a-tree>
        </a-spin>
      </div>
    </div>
    <div class="excel-view-right">
      <div class="botton-list excel-top">
        <div class="radio-div">
          <span
            @click="changeRadio(1)"
            :style="
              radioValue == 1
                ? 'color:' + color + ';border-color:' + color
                : 'border-color:rgba(0,0,0,0);color:rgba(0,0,0,0.65)'
            "
            >考核</span
          >
          <span
            @click="changeRadio(2)"
            v-has="'capacityExcel:view'"
            :style="
              radioValue == 2
                ? 'color:' + color + ';margin-left:20px;border-color:' + color
                : 'margin-left:20px;border-color:rgba(0,0,0,0);color:rgba(0,0,0,0.65)'
            "
            >产能</span
          >
          <span
            v-has="'sumbmitExcel:view'"
            @click="changeRadio(3)"
            :style="
              radioValue == 3
                ? 'color:' + color + ';margin-left:20px;border-color:' + color
                : 'margin-left:20px;border-color:rgba(0,0,0,0);color:rgba(0,0,0,0.65)'
            "
            >交稿</span
          >
          <span
            v-has="'sentence:view'"
            @click="changeRadio(4)"
            :style="
              radioValue == 4
                ? 'color:' + color + ';margin-left:20px;border-color:' + color
                : 'margin-left:20px;border-color:rgba(0,0,0,0);color:rgba(0,0,0,0.65)'
            "
            >佳句</span
          >
        </div>
        <div>
          <!--           <a-radio-group v-model="tableView" @change="viewChange">
            <a-radio-button value="excel">
              <a-icon type="unordered-list" />
            </a-radio-button>
            <a-radio-button value="graph">
              <a-icon type="line-chart" />
            </a-radio-button>
          </a-radio-group> -->
          <a-radio-group style="margin-left:30px;" v-model="timeView" @change="viewChange">
            <a-radio-button value="WEEK">
              周
            </a-radio-button>
            <a-radio-button value="MONTH">
              月
            </a-radio-button>
            <a-radio-button value="YEAR">
              年
            </a-radio-button>
          </a-radio-group>
        </div>
      </div>
      <div>
        <div v-if="radioValue == 1">
          <div v-if="tableView == 'excel'">
            <kpi-excel
              ref="kpiExcel"
              :groupType="groupType"
              :positionId="positionId"
              :departCode="departCode"
              :selectUser="selectUser"
              :timeView="timeView"
              :height="height"
            />
          </div>
          <div v-else>考核-图-{{ timeView }}</div>
        </div>
        <div v-else-if="radioValue == 2">
          <div v-if="tableView == 'excel'">
            <capacity-excel
              ref="capacityExcel"
              :groupType="groupType"
              :positionId="positionId"
              :departCode="departCode"
              :selectUser="selectUser"
              :timeView="timeView"
              :height="height"
            />
          </div>
          <div v-else>产能-图-{{ timeView }}</div>
        </div>
        <div v-else-if="radioValue == 3">
          <div v-if="tableView == 'excel'">
            <submit-excel
              ref="submitExcel"
              :groupType="groupType"
              :positionId="positionId"
              :departCode="departCode"
              :selectUser="selectUser"
              :timeView="timeView"
              :platformId="platformId"
              :height="height"
            />
          </div>
          <div v-else>交稿-图-{{ timeView }}</div>
        </div>
        <div v-else-if="radioValue == 4">
          <div v-if="tableView == 'excel'">
            <sentence-excel
              ref="sentenceExcel"
              :groupType="groupType"
              :positionId="positionId"
              :departCode="departCode"
              :selectUser="selectUser"
              :timeView="timeView"
              :platformId="platformId"
              :height="height"
            />
          </div>
          <div v-else>交稿-图-{{ timeView }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { simpleDebounce } from '@/utils/util'
import { getAction } from '../../api/manage'
import kpiExcel from './modules/kpiExcel'
import capacityExcel from './modules/capacityExcel'
import submitExcel from './modules/submitExcel'
import sentenceExcel from './modules/sentenceExcel'
export default {
  components: {
    kpiExcel,
    capacityExcel,
    submitExcel,
    sentenceExcel
  },
  data() {
    return {
      radioValue: 1,
      selectUserId: undefined,
      allUsers: [],
      color: '',
      tableView: 'excel',
      timeView: 'WEEK',
      height: 'calc(100vh - 305px)',
      treeData: [],
      groupType: 'POSITION',
      positionId: '',
      departCode: '',
      platformId: '',
      treeLoading: false,
      selectUser: '',
      searchValue: '',
      expandedKeys: ['A05-A05A02-DEPART', 'A05-A05A01-DEPART'],
      allTreeData: []
    }
  },
  computed: {
    backCss() {
      this.color = this.$store.state.app.color
      return {
        '--theme-color': this.color,
        '--table-height': this.height
      }
    }
  },
  watch: {
    radioValue() {
      this.$nextTick(() => {
        this.getTableOrGraphData()
        this.getAllData()
        this.selectUserId = undefined
      })
    },
    tableView() {
      this.$nextTick(() => {
        this.getTableOrGraphData()
        this.selectUserId = undefined
      })
    },
    timeView() {
      /*       let selectUserId = this.selectUserId
      this.selectUserId = undefined
      let departCode = this.departCode
      this.departCode = ''
      let positionId = this.positionId
      this.positionId = '' */
      this.$nextTick(() => {
        this.getTableOrGraphData()
        this.selectUserId = selectUserId
        this.positionId = positionId
        this.departCode = departCode
      })
    }
  },
  created() {
    this.getAllData()
  },
  mounted() {
    this.color = this.$store.state.app.color
  },
  methods: {
    getAllUsers() {
      this.treeData.forEach(d => {
        d.children?.forEach(pl => {
          pl.children?.forEach(po => {
            po.children?.forEach(u => {
              if (this.allUsers.every(item => item.userId != u.id)) {
                this.allUsers.push({ userId: u.id, userName: u.name, avatar: u.avatar })
              }
            })
          })
        })
      })
    },
    userSelect(value) {
      let depart = []
      let positions = []
      this.treeData.forEach(d => {
        d.children?.forEach(pl => {
          pl.children?.forEach(po => {
            po.children?.forEach(u => {
              if (u.id == value) {
                console.log(u)
                depart.push(pl.id)
                positions.push(po.id)
              }
            })
          })
        })
      })
      this.departCode = depart?.join(',')
      this.positionId = positions?.join(',')
      this.selectUser = value
      this.groupType = 'POSITION'
      this.getAllData()
      this.getTableOrGraphData()
    },
    getAllData: simpleDebounce(async function() {
      this.getTreeData()
    }, 200),
    getTableOrGraphData() {
      /* if (!this.positionId && !this.departCode && !this.selectUserId) return */
      if (this.tableView == 'excel') {
        if (this.radioValue == 1) {
          if (this.$refs.kpiExcel) this.$refs.kpiExcel.getTableData()
        } else if (this.radioValue == 2) {
          if (this.$refs.capacityExcel) this.$refs.capacityExcel.getTableData()
        } else if (this.radioValue == 3) {
          if (this.$refs.submitExcel) this.$refs.submitExcel.getTableData()
        } else if (this.radioValue == 4) {
          if (this.$refs.sentenceExcel) this.$refs.sentenceExcel.getTableData()
        }
      }
    },
    treeSelect(keys, e) {
      if (!keys.length) {
        this.expandedKeys.splice(this.expandedKeys.indexOf(e.node.value), 1)
        return
      }
      let key = keys[0]
      this.departCode = ''
      this.positionId = ''
      this.selectUser = ''
      this.positionId = ''
      this.groupType = key.split('-')[key.split('-').length - 1]
      if (this.groupType == 'DEPART' && this.radioValue != 3) {
        if (this.expandedKeys.indexOf(keys[0]) == -1) {
          this.expandedKeys.push(keys[0])
        } else {
          this.expandedKeys.splice(this.expandedKeys.indexOf(keys[0]), 1)
        }
        return
      }
      if (key.split('-')[key.split('-').length - 1] == 'PLATFORM' && this.radioValue == 3) {
        this.platformId = key.split('-')[1]
        this.groupType = 'POSITION'
      }
      if (this.groupType == 'DEPART') {
        this.groupType = 'POSITION'
      } else if (this.groupType == 'POSITION') {
        this.groupType = 'USER'
      }
      if (this.groupType == 'POSITION') {
        this.groupType = 'POSITION'
        this.departCode = key.split('-')[key.split('-').length - 2]
      } else if (this.groupType == 'USER') {
        if (key.split('-').length == 4) {
          this.departCode = key.split('-')[0]
          this.positionId = key.split('-')[1]
          this.selectUser = key.split('-')[2]
        } else {
          this.departCode = key.split('-')[0]
          this.positionId = key.split('-')[1]
        }
      }
      if (key.split('-')[key.split('-').length - 1] == 'PLATFORM' && this.radioValue == 3) {
        this.departCode = key.split('-')[0]
        this.positionId = ''
        this.groupType = 'POSITION'
      } else if (key.split('-')[key.split('-').length - 1] == 'DEPART' && this.radioValue == 3) {
        this.positionId = ''
        this.platformId = ''
        this.groupType = 'POSITION'
      }
      this.$nextTick(() => {
        this.getTableOrGraphData()
      })
    },
    viewChange() {},
    changeRadio(key) {
      this.radioValue = key
    },
    async getTreeData() {
      this.treeLoading = true
      let url = '/sys/user/list/kpiDepartPositionUserTree'
      if (this.radioValue == 3) {
        url = '/sys/user/list/finishDepartPositionUserTree'
      } else if (this.radioValue == 4) {
        url = '/sys/user/list/beautifulDepartPositionUserTree'
      } else {
        url = '/sys/user/list/kpiDepartPositionUserTree'
      }
      const res = await getAction(url)
      if (res.code == 200) {
        this.treeData = []
        if (!res.data) {
          this.treeLoading = false
          return
        }
        if (this.radioValue == 3) {
          res.data = res.data.length && res.data[0].lowers
          res.data?.forEach(i => {
            i.value = i.pid + '-' + i.id + '-' + i.type
            i.title = i.name
            i.key = i.pid + '-' + i.id + '-' + i.type
            i.type = i.type
            i.children = i.lowers
            i.scopedSlots = { title: 'title' }
            i.children?.forEach(d => {
              d.value = d.pid + '-' + d.id + '-' + d.type
              d.title = d.name
              d.key = d.pid + '-' + d.id + '-' + d.type
              d.type = d.type
              d.children = d.lowers
              d.scopedSlots = { title: 'title' }
              d.children?.forEach(p => {
                p.value = p.pid + '-' + p.id + '-' + p.type
                p.title = p.name
                p.key = d.id + '-' + p.id + '-' + p.type
                p.type = p.type
                p.children = p.lowers
                p.scopedSlots = { title: 'title' }
              })
            })
            this.treeData.push(i)
          })
        } else {
          res.data = res.data.length && res.data[0].lowers
          res.data?.forEach(i => {
            i.value = i.pid + '-' + i.id + '-' + i.type
            i.title = i.name
            i.key = i.pid + '-' + i.id + '-' + i.type
            i.type = i.type
            i.children = i.lowers
            i.scopedSlots = { title: 'title' }
            i.children?.forEach(d => {
              d.value = d.pid + '-' + d.id + '-' + d.type
              d.title = d.name
              d.key = d.pid + '-' + d.id + '-' + d.type
              d.type = d.type
              d.children = d.lowers
              d.scopedSlots = { title: 'title' }
              d.children?.forEach(p => {
                p.value = p.pid + '-' + p.id + '-' + p.type
                p.title = p.name
                p.key = d.id + '-' + p.id + '-' + p.type
                p.type = p.type
                p.children = p.lowers
                p.scopedSlots = { title: 'title' }
                p.children?.forEach(u => {
                  u.value = d.id + '-' + p.id + '-' + u.id + '-' + u.type
                  u.title = u.name
                  u.key = d.id + '-' + p.id + '-' + u.id + '-' + u.type
                  u.type = u.type
                  u.scopedSlots = { title: 'title' }
                })
              })
            })
          })
          this.allTreeData = res.data
          this.treeData = res.data
        }
        this.getAllUsers()
        this.expandedKeys = ['A05-A05A02-COMPANY', 'A05-A05A01-COMPANY']
        this.treeLoading = false
      } else {
        this.$message.error(res.msg || res.message)
        this.treeLoading = false
      }
    },
    onSearch(value) {
      this.searchValue = value
      let arr = []
      this.expandedKeys = []
      this.treeData = JSON.parse(JSON.stringify(this.allTreeData))
      this.treeData = this.getSaerchTreeDate(this.treeData, arr, value)
      console.log(this.treeData)
      if (value) {
        this.expandedKeys = [...new Set(this.expandedKeys)]
      } else {
        this.expandedKeys = ['A05-A05A02-COMPANY', 'A05-A05A01-COMPANY']
      }
    },
    expand(keys) {
      this.expandedKeys = keys
    },
    getSaerchTreeDate(treeData, arr, value) {
      treeData?.forEach(i => {
        if (i.title.indexOf(value) > -1) {
          if (arr.every(item => item.title != i.title)) arr.push(i)
          this.expandedKeys.push(i.key)
        }
        let hasValue = false
        i.children?.forEach(d => {
          if (d.title.indexOf(value) > -1) {
            if (arr.every(item => item.title != i.title)) {
              arr.push(i)
            }
            this.expandedKeys.push(i.key)
            this.expandedKeys.push(d.key)
            hasValue = true
          }
          let hasValueD = false
          d.children?.forEach(p => {
            if (p.title.indexOf(value) > -1) {
              if (arr.every(item => item.title != i.title)) {
                arr.push(i)
              }
              this.expandedKeys.push(i.key)
              this.expandedKeys.push(d.key)
              this.expandedKeys.push(p.key)
              hasValue = true
              hasValueD = true
            }
            let hasValueP = false
            p.children?.forEach(u => {
              if (u.title.indexOf(value) > -1) {
                if (arr.every(item => item.title != i.title)) {
                  arr.push(i)
                }
                this.expandedKeys.push(i.key)
                this.expandedKeys.push(d.key)
                this.expandedKeys.push(p.key)
                hasValue = true
                hasValueD = true
                hasValueP = true
              } else {
                /* p.children=p.children.filter(item=>item.id!=u.id) */
              }
            })
            /* if (!hasValueP) {
              d.children=d.children.filter(item => item.id != p.id)
            } */
          })
          /*  if (!hasValueD) {
            i.children=i.children.filter(item => item.id != d.id)
          } */
        })
        if (!hasValue) {
          treeData = treeData.filter(item => item.id != i.id)
        }
      })
      return arr
    }
  }
}
</script>

<style scoped lang="less">
.ant-radio-button-wrapper-checked[data-v-48e96165]:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: none !important;
}
.botton-list {
  display: flex;
  justify-content: space-between;
}
.tree-list {
  min-height: 70px;
  max-height: var(--table-height);
  border: 1px solid rgb(233, 232, 232);
  border-radius: 4px;
  overflow-y: auto;
  cursor: pointer;
}
.tree-list::-webkit-scrollbar-button {
  height: 0;
}
.tree-list::-webkit-scrollbar {
  border-radius: 0;
  width: 3px;
}
.tree-list::-webkit-scrollbar-track {
  border-radius: 0;
}
.tree-list::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
.tree-list::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: var(--theme-color);
  background: #fff;
  border-color: var(--theme-color);
  box-shadow: -1px 0 0 0 var(--theme-color);
}
.radio-div {
  span {
    padding-bottom: 5px;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
  }
}
.excel-view {
  padding: 24px 48px 24px 48px;
  display: flex;
  height: calc(100vh - 135px);
  .excel-view-letf {
    width: 15%;
    height: 100%;
    padding-right: 30px;
  }
  .excel-view-right {
    width: 85%;
  }
}
.excel-top {
  padding-bottom: 24px;
}
</style>
